import React, { useCallback } from "react";
import { getOptionsMenuHeight, OptionsMenu, OptionsMenuItem } from "../OptionsMenu";
import { useBottomSheet } from "../../screens/BottomSheetScreen";
import {
  useCookingSessionAudioEnabled,
  useCookingSessionHasScalingInfo,
  useCookingSessionRecipeId,
} from "../../lib/cooking/CookingSessionsSelectors";
import { usePaywallStatus } from "../PaywallDetector";
import { CookingSessionId } from "@eatbetter/cooking-shared";
import { useSystemSetting } from "../../lib/system/SystemSelectors";
import { useRecipeSourceType } from "../../lib/recipes/RecipesSelectors";

const strings = {
  scale: "Scale & Convert Recipe",
  textSize: "Adjust Text Size",
  share: "Share Recipe",
  startAudio: "Start Audio Instructions",
  stopAudio: "Stop Audio Instructions",
  reportIssue: "Report a Recipe Issue",
};

export const getRecipeInKitchenOptionsHeight = (showScaling: boolean) => {
  let itemCount = 4;
  if (showScaling) {
    itemCount += 1;
  }
  return getOptionsMenuHeight(itemCount);
};

interface Props {
  cookingSessionId: CookingSessionId;
  onTapAudio: () => void;
  onTapShare: () => void;
  onTapTextSize: () => void;
  scaleAndConvert: { icon: () => React.ReactElement; onPress: () => void };
  onTapReportIssue: () => void;
}

export const RecipeInKitchenOptions = React.memo((props: Props) => {
  const bottomSheet = useBottomSheet();
  const recipeId = useCookingSessionRecipeId(props.cookingSessionId);
  const recipeSourceType = useRecipeSourceType(recipeId);

  const audioOn = useCookingSessionAudioEnabled();

  const { paywallIsUp } = usePaywallStatus(recipeId);

  const scalingFeatureEnabled = !!useSystemSetting("scalingAndConversion");
  const hasScalingData = !!useCookingSessionHasScalingInfo(props.cookingSessionId);
  const showScalingOption = scalingFeatureEnabled && hasScalingData;

  const onTapScale = useCallback(() => {
    bottomSheet?.closeSheetAndGoBack();
    setTimeout(() => props.scaleAndConvert.onPress(), 300);
  }, [bottomSheet, props.scaleAndConvert.onPress]);

  const onTapTextSize = useCallback(() => {
    bottomSheet?.closeSheetAndGoBack();
    setTimeout(() => props.onTapTextSize(), 300);
  }, [bottomSheet, props.onTapShare]);

  const onTapShare = useCallback(() => {
    bottomSheet?.closeSheetAndGoBack();
    setTimeout(() => props.onTapShare(), 300);
  }, [bottomSheet, props.onTapShare]);

  const onTapAudio = useCallback(() => {
    bottomSheet?.closeSheetAndGoBack();
    props.onTapAudio();
  }, [bottomSheet, props.onTapAudio]);

  const onTapReportIssue = useCallback(() => {
    bottomSheet?.closeSheetAndGoBack();
    setTimeout(() => props.onTapReportIssue(), 300);
  }, [bottomSheet, props.onTapReportIssue]);

  return (
    <OptionsMenu>
      {showScalingOption && (
        <OptionsMenuItem icon={props.scaleAndConvert.icon} isFirst text={strings.scale} onPress={onTapScale} />
      )}
      <OptionsMenuItem isFirst={!showScalingOption} icon="text" text={strings.textSize} onPress={onTapTextSize} />
      <OptionsMenuItem icon="share" text={strings.share} onPress={onTapShare} />
      <OptionsMenuItem
        disabled={!audioOn && paywallIsUp}
        icon={audioOn ? "audioOn" : "audioOff"}
        text={audioOn ? strings.stopAudio : strings.startAudio}
        onPress={onTapAudio}
      />
      {recipeSourceType !== "user" && (
        <OptionsMenuItem icon="flag" text={strings.reportIssue} onPress={onTapReportIssue} />
      )}
    </OptionsMenu>
  );
});
